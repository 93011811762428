import React, { useEffect } from "react";
import { Link } from "../";
import appinfo from "../../clientsJSON";
import { utils } from "../../utils"; 

export default function Button(props) {
    
    const { 
        buttonText = "" , 
        buttonStyle = {},
        extraClasses = "",
        showIcon = false,
        iconName = "",
        iconReverse = false,
        iconStyle = {},
        buttonLink = "",
        newTab = false,

        razorpayButton = false,
        paymentButtonId = "",
        razorpayButtonStyle = {}
    } = props;

    useEffect(() => {

        if(razorpayButton){
            const Script = document.createElement("script");
            const Form = document.getElementById('paymentButton');
            Script.setAttribute('src','https://checkout.razorpay.com/v1/payment-button.js')
            Script.setAttribute('data-payment_button_id',paymentButtonId)
            // Script.setAttribute('data-button_theme',"brand-color")
            Form.appendChild(Script);
        }
    } , [razorpayButton]);

    let tabletStyle = 
            utils.isDeviceType() === 'T' &&
            razorpayButton 
                ? {marginLeft : '100px'} 
                : {}


    return (
        <div className = { !razorpayButton && extraClasses }>
            { buttonText !== "" &&
            <Link
                    link = {buttonLink}
                    newTab = {newTab}
                >
                <button className={"button-default "+extraClasses} 
                    style={
                        { 
                            marginTop: "25px",
                            backgroundColor: appinfo.button_color, 
                            ...tabletStyle,
                            ...buttonStyle
                        }
                    }
                >
                    {buttonText + " "}
                    { 
                        showIcon &&
                        <i className="material-icons" 
                            style={{
                                verticalAlign: "sub", 
                                height: "16px",
                                paddingLeft : iconReverse ? "0px" : "10px",
                                paddingRight: !iconReverse ? "0px" :"10px",
                                float: iconReverse ? "left" : "right",
                                ...iconStyle
                            }}>
                            {iconName}
                        </i>
                    }
                </button>
            </Link>
            }   
            {
                razorpayButton &&
                <div 
                    style={{zIndex: 20, ...razorpayButtonStyle}} 
                    className={"rzrPay-button " + extraClasses}
                >
                    <form id="paymentButton">
                    </form>
                </div>
            }
        </div>
    );
}