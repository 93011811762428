import React, { Component } from "react";
import appinfo from "../../clientsJSON";

export default class TimelineCard extends Component{
    
    render() {
        const {
            contentProp = {},
            buttonProps = {},
            containerProps = {}
        } = this.props;

        return(
            <div className="timeline-item">
                <div
                    className="timeline-img"
                    style={{ background: appinfo.primary_color }}
                >
                </div>
                <div className="timeline-content timeline-card js--fadeInRight"
                    style={{ ...containerProps }}
                >
                    <div className="timeline-img-header"
                    style={{ background: `URL(${contentProp.imageSource}) center center no-repeat` }}>
                        <h2>
                            {contentProp.title}
                        </h2>
                    </div>
                    <div className="date" style={{ background: appinfo.button_color }}>
                        {contentProp.date}
                    </div>
                    <p>
                        {contentProp.description}
                    </p>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <a href={contentProp.link}
                            className="bnt-more"
                            style={{
                                textDecoration: "none",
                                backgroundColor: appinfo.button_color,
                                ...buttonProps
                            }}
                        >
                            More
                        </a>
                        <div
                            className="bnt-more"
                            style={{
                                textDecoration: "none",
                                color: appinfo.button_color,
                                boxShadow: 'none',
                                fontWeight: 'bold',
                                ...buttonProps
                            }}
                        >
                            {contentProp.sourcePage}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}