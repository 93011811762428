import React, { Component } from "react";
import {
    ContentLayout,
    TypingEffect,
    Button
} from "..";
import appinfo from "../../clientsJSON";

export default class BannerTemplate5 extends Component {
    render(){
        const {
            container = {}
         } = this.props;
        
        
        let buttons = container.buttons; 
        let mobile = window.screen.width < 768;
        
        return(
            <div className="banner-container-home" 
                style={{    
                            flexDirection: mobile ? "column" : 
                            ( container.container_reverse ? "row" : "row-reverse"),
                            backgroundColor: appinfo.background_color,
                            backgroundImage: container.image_path ? 
                                        `url(${container.image_path})` : "",
                            backgroundRepeat : "no-repeat",
                            backgroundSize : "cover",
                            backgroundPosition: "center",
                            height: "calc(100vh - 32px)"
                        }}>
                <div className="banner-image center" 
                    style={
                        {
                            minWidth: "100%",
                            minHeight: "400px",
                            marginTop: "10%"
                        }
                    }
                >
                    <ContentLayout 
                        {...container.contentProps} 
                    />
                    {   container.typingEffect !== undefined &&
                        container.typingEffect.strings.length !== 0 &&
                        <TypingEffect
                            loop = { container.typingEffect.loop || true }
                            strings = { container.typingEffect.strings || [] }
                        />
                    }
                    { buttons !== undefined &&
                        <div className="flex wrap">
                            {
                                buttons.map( buttonProp => 
                                    <Button
                                        buttonText = { buttonProp.buttonText || "" } 
                                        buttonStyle = {{ ...buttonProp.buttonStyle , margin: "20px" }}
                                        extraClasses = { buttonProp.extraClasses ||  ""}
                                        showIcon = { buttonProp.showIcon || false }
                                        iconName = { buttonProp.iconName || ""}
                                        iconReverse = { buttonProp.iconReverse || false } 
                                        iconStyle = { buttonProp.iconStyle || {}}
                                        buttonLink = { buttonProp.buttonLink || ""}
                                        newTab = { buttonProp.newTab || false}
                                        razorpayButton = {buttonProp.razorpayButton || false}
                                        paymentButtonId = {buttonProp.paymentButtonId || ""}
                                        razorpayButtonStyle = {buttonProp.razorpayButtonStyle || {}}
                                    />
                                )
                            }   
                        </div>
                    }
                </div>
            </div>
        );
    }
}