import React,{Component} from "react";
import appinfo from "../../clientsJSON";
// import "./index.css";
import {
    Menu,
    Footer,
    Layout,
    ContactLayout,
    EmbedUrlLayout,
    NituWidget
} from "../../component";


class Contact extends Component {

    renderLayout = (i, length = 1) => {
        return(
            <Layout 
                    columns={length} 
                    extraClasses = { "center padding-40" }
                >
                    <span 
                        className={"material-icons"} 
                        style={{fontSize: 60}} 
                    > 
                        business 
                    </span>
                    <h3>{i.title}</h3> 
                    <>
                        { 
                            i.address.split(",").map( i => {
                                return <address>{i}</address>
                            })
                        }
                    </>
            </Layout>
        )
    }

    render(){
        document.title = "Contact Us | " + appinfo.name;

        return (
            <div>
                <Menu/>
                <div className="mp-container">
                    <h3  style={{textAlign:"center",paddingTop:50,textTransform:"uppercase",color:appinfo.primaryTextColor}}></h3>
                    <NituWidget
                        clientId = { appinfo["x-access-client-id"] || ""}
                        formId = { appinfo["form"] || ""}
                    />
                    <div className="flex wrap">
                        {
                            appinfo.footer.content.address.map( i => {
                                return this.renderLayout(i, appinfo.footer.content.address.length);
                            })
                        }                 
                    </div>
                    <ContactLayout /> 
                    {   appinfo.map.length !== 0 && 
                        <EmbedUrlLayout source = {appinfo.map} />
                    }
                </div>
                <Footer/>
            </div>
        )
    }    
}
export default Contact;