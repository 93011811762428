import React, { Component } from 'react';

import appinfo from "../../clientsJSON";
import { 
    Layout, 
    Link,
    SingleImageLayout
} from '..';
import { utils } from '../../utils';

export default class FooterTemplate1 extends Component {

    renderList = (menu, showIcon = false, float = "") => {
        let link = showIcon ?  utils.getLink(menu) : "";

        return (
            <li className="listmenu" style={{float}}>
                <Link
                    {...menu}
                    linkText={menu.value || ""}
                    link={ link ? link : menu.link}
                    linkStyle={menu.style || { marginTop: 5 }}
                    showIcon = {showIcon}
                    iconName = {menu.icon || ""}
                />
            </li>
        )
    }

    render() {
        let ftr = appinfo.footer.content;

        return (
            <Layout 
                extraClasses = "footer-layout flex center"
            >
                <Layout columns={2}>
                    <SingleImageLayout
                        imageSource = {ftr.logo}
                        imageAlt = {"logo"}
                        // imageWidth = {"60px"}
                        imageHeight = {"60px"}
                        extraClasses = { "left" }
                    />
                </Layout>
                <Layout extraClasses = "footer-links" columns={1}>
                    <ul className="menu footer-menu center">
                            {ftr.menu.map(menu => this.renderList(menu, false, "left") ) }
                    </ul>
                </Layout>
                <Layout extraClasses="footer-links footer-icons" columns={2}>
                    <ul className="menu footer-menu right">
                            {ftr.contact.map(menu => this.renderList(menu, true, "right") ) }
                    </ul>
                </Layout>
            </Layout>
        )
    }
}