import React, { Component } from "react";
import { 
    ContentLayout, 
    Button,
    Layout,
    Slider,
    PricingCard1,
    PricingCard2
} from "..";

export default class PricingSection extends Component{


    renderDetail = (contentProp = {}) => {
        const{ 

            containerProps = {},
            imageProps = {},
            linkProps = {},
            slider = false,
            iconProps = {},
            template = 1

        } = this.props;

        let imageProp = { ...imageProps, imageSource: contentProp.imageSource };
        let linkProp = { ...linkProps, 
                         link: contentProp.link || linkProps.link,
                         linkText: contentProp.linkText || linkProps.linkText
                        };

        let containerProp = { ...containerProps, columns: 1 };

        switch(template) {
            case 2 : return <PricingCard2 
                                    contentProps = {contentProp || {}}
                                    containerProps = { containerProp }
                                />;
            default : return(
                                    <PricingCard1
                                        containerProps={(slider ? containerProp : containerProps) || {}}
                                        imageProps =  {imageProp || {}}
                                        linkProps = {linkProp || {}}
                                        contentProps = {contentProp || {}}
                                        iconProps = { iconProps || {}}
                                    />
                                );
        }
    }

    render(){
        const {  
            containerProps = {},
            contentProps = [], 
            header = {}, 
            backgroundColor = "#000",
            sectionStyle = {}, 
            buttonProps = {} ,
            slider = false
        } = this.props;

        let noOfCards = containerProps.columns || 4 ;
        
        return(
        <Layout
            layoutStyle={{backgroundColor, ...sectionStyle }}
            extraClasses = "no-radius"
        >
            <div className="container padding-70" id = "hd">
                <ContentLayout
                    title={header.title || ""}
                    description={header.description || ""}
                    titleStyle={header.titleStyle || {}}
                    descriptionStyle={header.descriptionStyle || {}}
                    subTitle={header.subTitle || ""}
                    subTitleStyle={header.subTitleStyle || {}}
                    contentStyle={header.contentStyle || {}}
                    extraClasses={header.extraClasses || "padding-50"}
                    tag = {{ title: "h2", subTitle: "h4" , ...header.tag }}
                />
                {
                    !slider &&
                    <div className="flex wrap" style={{width: "100%"}}>
                        { contentProps.map( content => <>{ this.renderDetail(content) }</>) }
                    </div>
                }
                {
                    slider && 
                    <Slider noOfCards = {noOfCards}>
                        { contentProps.map( content => { this.renderDetail(content) }) }
                    </Slider>
                }
                { 
                    !["", undefined, null].includes(buttonProps.buttonLink)  &&
                    <Button
                        buttonText = {buttonProps.buttonText || ""} 
                        buttonStyle = {buttonProps.buttonStyle || {}}
                        extraClasses = {buttonProps.extraClasses || ""}
                        showIcon = {buttonProps.showIcon || false }
                        iconName = {buttonProps.iconName || ""}
                        iconReverse = { buttonProps.iconReverse || false } 
                        iconStyle = { buttonProps.iconStyle || {}}
                        buttonLink = {buttonProps.buttonLink || ""}
                        newTab = {buttonProps.newTab || false }
                        razorpayButton = {buttonProps.razorpayButton || false}
                        paymentButtonId = {buttonProps.paymentButtonId || ""}
                        razorpayButtonStyle = {buttonProps.razorpayButtonStyle || {}}
                    />
                }
            </div>
        </Layout>
        );
    }
}