import React, { Component } from "react";
import {
    Banner,
    DefaultSection,
    AlternateLayout,
    Accordion,
    ContentLayout,
    ContactLayout,
    ListSection,
    MediaSection,
    PartnerSection,
    ReviewSection,
    NituWidget,
    TableLayout
} from "../../component";
import appinfo from "../../clientsJSON";
import "./index.scss";

export default class SingleProduct1 extends Component {

    render() {
        const { content } = this.props;
        
    return (
            content.map( (single, index ) => {

                let style = { paddingBottom: 40, paddingTop: 40 };
                style["backgroundColor"] = index % 2 !== 0 ? "white" : "#F6F6F6";
                style["display"] = single.show ? "visible" : "none";
            
                switch (single.cardType) {
                    case "reviews": return <ReviewSection
                        containerProps={single.content.containerProps || {}}
                        imageProps={single.content.imageProps || {}}
                        logoProps={single.content.logoProps || {}}
                        contentProps={single.content.contentProps || []}
                        header={single.content.header || {}}
                        backgroundColor={single.content.backgroundColor || "transparent"}
                        buttonProps={single.content.buttonProps || {}}
                        slider={single.content.slider || false}
                        template={single.content.template || 1}
                        sectionStyle={single.content.sectionStyle || {}}
                        id = { single.content.id || "reviews" }
                    />;
                    case "products": return <DefaultSection
                        containerProps={single.content.containerProps || {}}
                        imageProps={single.content.imageProps || {}}
                        linkProps={single.content.linkProps || {}}
                        contentProps={single.content.contentProps || []}
                        header={single.content.header || {}}
                        backgroundColor={single.content.backgroundColor || "transparent"}
                        buttonProps={single.content.buttonProps || {}}
                        slider={single.content.slider || false}
                        sectionStyle={single.content.sectionStyle || {}}
                        id = { single.content.id || "products"}
                    />;
                    case "partners": return <PartnerSection
                        containerProps={single.content.containerProps || {}}
                        imageProps={single.content.imageProps || {}}
                        logos={single.content.logos || []}
                        header={single.content.header || {}}
                        backgroundColor={single.content.backgroundColor || "transparent"}
                        sectionStyle={single.content.sectionStyle || {}}
                        id = { single.content.id || "partners"}
                    />;
                    case "blogs": return <DefaultSection
                        containerProps={single.content.containerProps || {}}
                        imageProps={single.content.imageProps || {}}
                        linkProps={single.content.linkProps || {}}
                        contentProps={single.content.contentProps || []}
                        header={single.content.header || {}}
                        backgroundColor={single.content.backgroundColor || "transparent"}
                        buttonProps={single.content.buttonProps || {}}
                        slider={single.content.slider || false}
                        cardType={single.content.cardType || "blogs"}
                        sectionStyle={single.content.sectionStyle || {}}
                        id = { single.content.id || "blogs"}
                    />;
                    case "icons": return <DefaultSection
                        containerProps={single.content.containerProps || {}}
                        iconProps={single.content.iconProps || {}}
                        linkProps={single.content.linkProps || {}}
                        contentProps={single.content.contentProps || []}
                        header={single.content.header || {}}
                        backgroundColor={single.content.backgroundColor || "transparent"}
                        buttonProps={single.content.buttonProps || {}}
                        slider={single.content.slider || false}
                        cardType={single.content.cardType || "icons"}
                        sectionStyle={single.content.sectionStyle || {}}
                        id = { single.content.id || "icons"}
                    />;
                    case "media": return <MediaSection
                        containerProps={single.content.containerProps || {}}
                        imageProps={single.content.imageProps || {}}
                        linkProps={single.content.linkProps || {}}
                        contentProps={single.content.contentProps || []}
                        header={single.content.header || {}}
                        backgroundColor={single.content.backgroundColor || "transparent"}
                        buttonProps={single.content.buttonProps || {}}
                        slider={single.content.slider || false}
                        sectionStyle={single.content.sectionStyle || {}}
                        id = { single.content.id || "media"}
                    />;
                    case "alternate": return <AlternateLayout
                        reverse={single.content.reverse || false}
                        header={single.content.header || {}}
                        imageProps={single.content.imageProps || {}}
                        videoProps={single.content.videoProps || {}}
                        contentProps={single.content.contentProps || {}}
                        linkProps={single.content.linkProps || {}}
                        buttonProps = {single.content.buttonProps || {}}
                        backgroundColor={single.content.backgroundColor || "transparent"}
                        sectionStyle={single.content.sectionStyle || {}}
                        id = { single.content.id || "alternate"}
                    />;
                    case "content" : return <ContentLayout
                        title = {single.content.contentProps.title || ""}
                        description = { single.content.contentProps.description || ""} 
                        contentStyle = { { ...single.content.contentProps.contentStyle }}
                        titleStyle = {single.content.contentProps.titleStyle || {}} 
                        descriptionStyle = {single.content.contentProps.descriptionStyle || {}}
                        subTitle={single.content.contentProps.subTitle || ""}
                        subTitleStyle={single.content.contentProps.subTitleStyle || {}}
                        extraClasses = {single.content.contentProps.extraClasses || "center"}
                        tag = {single.content.contentProps.tag || {}}
                        id = { single.content.id || "content"}
                    />;
                    case "widget" : return <NituWidget
                        clientId = { appinfo["x-access-client-id"] || ""}
                        formId = { appinfo["form"] || ""}
                        width = { single.content.width || "100%"}
                        height = { single.content.height || "400px"}
                        style = { single.content.style || {}}
                        id = { single.content.id || "widget"}
                    />
                    case "banner" : return <Banner
                        container = {single.content || {}}
                        template = {single.content.template || 2}
                        id = { single.content.id || "banner"}
                    />
                    case "contact" : return <ContactLayout 
                            id = { single.content.id || "contact"}
                        />
                    case "accordion" : return <Accordion 
                            list = {single.content.list || {}} 
                            sectionStyle = {single.content.sectionStyle || {} }
                            header = {single.content.header || {}}
                        />;
                    case "list" : return <ListSection
                            contentProps = {single.content.contentProps || []} 
                            headerProps = {single.content.headerProps || {}} 
                            backgroundColor = {single.content.backgroundColor || ""}
                            sectionStyle = {single.content.sectionStyle || {}}
                    />;
                    case "table" : return <TableLayout 
                            title = { single.content.title || ""}
                            titleStyle =  { single.content.titleStyle || {}}
                            table = { single.content.table || []}
                            containerProps = { single.content.containerProps || {}}
                            tag = { single.content.tag || { title: "h3"} }
                    />;
                    default : return <div></div>;
                }
            })
        );
    }
}