import React, { Component } from "react";
import { IconLayout, Layout, ContentLayout } from "../";

export default class Accordion extends Component{
    constructor (props)
    {
        super(props);
        this.state = {
            activeAccordion : -1
        }
    }    

    changeAccordion = (index) => {

        this.setState( (prevState) => ({
            activeAccordion : prevState.activeAccordion === index ? -1 : index 
        }) )

    }

    render(){
        
        const { activeAccordion } = this.state;
        const { 
            list = [], 
            sectionStyle = {} ,
            header = {}
        } = this.props;
      
        return(
            <Layout
                extraClasses = {"padding-70"}
                columns = {1}
                layoutStyle = {{ borderRadius : "0px", ...sectionStyle  }}
            >
                <div className = "container">
                    <ContentLayout
                        title={header.title || ""}
                        description={header.description || ""}
                        titleStyle={header.titleStyle || {}}
                        descriptionStyle={header.descriptionStyle || {}}
                        subTitle={header.subTitle || ""}
                        subTitleStyle={header.subTitleStyle || {}}
                        contentStyle={header.contentStyle || {}}
                        extraClasses={header.extraClasses || ""}
                        tag = {{ title: "h2", subTitle: "h4" , ...header.tag }}
                    />
                    {
                        list.length !== 0 &&
                        list.map(
                            (i, index) => {
                                return (
                                    <div className = { `accordion ${index === activeAccordion ?  "active" : ""}` }>
                                        <div className = {`accordionKey`}
                                            onClick = { () => this.changeAccordion(index)}
                                        >
                                            <div> {i.key} </div>
                                            <IconLayout 
                                                iconName = {
                                                    index === activeAccordion ?
                                                    "expand_less" :
                                                    "expand_more"
                                                }
                                                extraClasses = {""}
                                            />
                                        </div>
                                        <div className = { ` ${index === activeAccordion ?  "accordion-hr" : ""}`} />
                                        {   
                                            <div 
                                                className = {`panel`}
                                                style = {{ display: index === activeAccordion ? "block" : "none" }}
                                            >
                                                {i.value}
                                            </div>
                                        }
                                    </div>
                                );
                            }
                        )
                    }
                </div>
            </Layout>
        );
    }
}