import React, { Component } from 'react';
import { MayIHelpYou } from '..';

import appinfo from "../../clientsJSON";
import Copyright from "./copyright";
import FooterTemplate1 from "./template1.js";
import FooterTemplate2 from "./template2.js";

export default class Footer extends Component {

    render() {
        let ftr = appinfo.footer;
        let copyrightProps = {
            backgroundColor :  ftr.copyright.backgroundColor || ""  ,
            textColor : ftr.copyright.textColor || "" ,
            linkColor : ftr.copyright.linkColor || "" 
        }
        let copyrightShow = ftr.copyright.show || false;

        switch(ftr.template){
            case 1 : return <>
                                <FooterTemplate1 content = {ftr.content || {}} />
                                <Copyright {...copyrightProps} enable = { copyrightShow }/>
                                {
                                    ftr?.floatingWhatsapp &&
                                    <MayIHelpYou />
                                }
                            </>;
            case 2 : return <>
                                <FooterTemplate2 content = {ftr.content || {}} />
                                <Copyright {...copyrightProps}  enable = { copyrightShow }/>
                                {
                                    ftr?.floatingWhatsapp &&
                                    <MayIHelpYou />
                                }
                            </>;
            default : return <Copyright {...copyrightProps} enable = { copyrightShow }/>;
        }
    }
}