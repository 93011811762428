import React,{Component} from "react";
import appinfo from "../../clientsJSON";
// import "./index.css";
import {
    Container,
    ContentLayout
} from "../../component";

class Privacy extends Component {
    render(){
        document.title = "Privacy Policy | " + appinfo.name;
        return (
            <Container>
                    <ContentLayout
                        title={appinfo.policies.privacy.title}
                        description={appinfo.policies.privacy.description}
                        descriptionStyle={{textAlign: 'left', padding: '40px 0px'}}
                        contentStyle={{minHeight: 'calc(50vh)'}}
                        extraClasses={"padding-70 center"}
                        tag = {{ title: "h2" }}
                    />
            </Container>
        )
    }
}
export default Privacy;