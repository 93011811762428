import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./index.css";
import "./index.scss";
import "../../index.css";
import appinfo from "../../clientsJSON";
import {
    Banner,
    Menu,
    Footer,
    Sections,
    Seo,
    DefaultSection,
    AlternateLayout,
    Accordion,
    ContentLayout,
    ContactLayout,
    MediaSection,
    PricingSection,
    PartnerSection,
    ReviewSection,
    NituWidget,
} from "../../component";




class Home extends Component {
    constructor() {
        super();
        this.state = {}
    }


    render() {
        document.title = appinfo.name;
        
        let source = "";
        source = `https://development.moonproduct.in?id=${appinfo["x-access-client-id"]}&form=${appinfo["form"]}`

        return (
            <div>
                <Menu />
                <div style={{marginTop: 60}}>
                    {/* Section Rendering */}
                    {
                        appinfo.home_sections.map((single, index) => {
                        if(!single.show){
                            return <div></div>;
                        }  
                        else{   
                            let style = { paddingBottom: 40, paddingTop: 40 };
                            style["backgroundColor"] = index % 2 !== 0 ? "white" : "#F6F6F6";
                            style["display"] = single.show ? "visible" : "none";                      
                            switch (single.cardType) {
                                case "section": return (

                                    <div className="About"
                                    >

                                        <h1 style={{ textAlign: "center", color: appinfo.primaryTextColor }}>{single.title}</h1>
                                        {/* {single.type.includes(1)&&<p style={{textAlign:"center"}}>{single.descripition}</p>} */}
                                        {single.iconshow && single.image && <div>
                                            <img src={single.image} alt={single.title} style={{ width: "77px", height: "77px", marginLeft: "auto", marginRight: "auto", display: "flex", backgroundColor: "transparent" }} /></div>}
                                        <Sections content={single} index={index} />
                                        {single.type.includes(1) && <p style={{ textAlign: "center" }}>
                                            <a href={"/" + appinfo.services.title} style={{ textDecoration: "none", color: appinfo.secondary_color }}>
                                                <button style={{ color: appinfo.secondary_color, border: "none", borderRadius: 0, backgroundColor: appinfo.button_color }} className="button" >VIEW ALL {appinfo.services.title} <i class="material-icons" style={{ verticalAlign: "sub", height: 16, fontSize: "large" }}>arrow_forward</i></button>
                                            </a>
                                        </p>}

                                    </div>
                                );
                                case "reviews": return <ReviewSection
                                    containerProps={single.content.containerProps || {}}
                                    imageProps={single.content.imageProps || {}}
                                    logoProps={single.content.logoProps || {}}
                                    contentProps={single.content.contentProps || []}
                                    header={single.content.header || {}}
                                    backgroundColor={single.content.backgroundColor || "transparent"}
                                    buttonProps={single.content.buttonProps || {}}
                                    slider={single.content.slider || false}
                                    template={single.content.template || 1}
                                    sectionStyle={single.content.sectionStyle || {}}
                                    id = { single.content.id || "reviews" }
                                />;
                                case "products": return <DefaultSection
                                    containerProps={single.content.containerProps || {}}
                                    imageProps={single.content.imageProps || {}}
                                    linkProps={single.content.linkProps || {}}
                                    contentProps={single.content.contentProps || []}
                                    header={single.content.header || {}}
                                    backgroundColor={single.content.backgroundColor || "transparent"}
                                    buttonProps={single.content.buttonProps || {}}
                                    slider={single.content.slider || false}
                                    sectionStyle={single.content.sectionStyle || {}}
                                    id = { single.content.id || "products"}
                                />;
                                case "partners": return <PartnerSection
                                    containerProps={single.content.containerProps || {}}
                                    imageProps={single.content.imageProps || {}}
                                    logos={single.content.logos || []}
                                    header={single.content.header || {}}
                                    backgroundColor={single.content.backgroundColor || "transparent"}
                                    sectionStyle={single.content.sectionStyle || {}}
                                    id = { single.content.id || "partners"}
                                />;
                                case "blogs": return <DefaultSection
                                    containerProps={single.content.containerProps || {}}
                                    imageProps={single.content.imageProps || {}}
                                    linkProps={single.content.linkProps || {}}
                                    contentProps={single.content.contentProps || []}
                                    header={single.content.header || {}}
                                    backgroundColor={single.content.backgroundColor || "transparent"}
                                    buttonProps={single.content.buttonProps || {}}
                                    slider={single.content.slider || false}
                                    cardType={single.content.cardType || "blogs"}
                                    sectionStyle={single.content.sectionStyle || {}}
                                    id = { single.content.id || "blogs"}
                                />;
                                case "icons": return <DefaultSection
                                    containerProps={single.content.containerProps || {}}
                                    iconProps={single.content.iconProps || {}}
                                    linkProps={single.content.linkProps || {}}
                                    contentProps={single.content.contentProps || []}
                                    header={single.content.header || {}}
                                    backgroundColor={single.content.backgroundColor || "transparent"}
                                    buttonProps={single.content.buttonProps || {}}
                                    slider={single.content.slider || false}
                                    cardType={single.content.cardType || "icons"}
                                    sectionStyle={single.content.sectionStyle || {}}
                                    id = { single.content.id || "icons"}
                                />;
                                case "media": return <MediaSection
                                    containerProps={single.content.containerProps || {}}
                                    imageProps={single.content.imageProps || {}}
                                    linkProps={single.content.linkProps || {}}
                                    contentProps={single.content.contentProps || []}
                                    header={single.content.header || {}}
                                    backgroundColor={single.content.backgroundColor || "transparent"}
                                    buttonProps={single.content.buttonProps || {}}
                                    slider={single.content.slider || false}
                                    sectionStyle={single.content.sectionStyle || {}}
                                    id = { single.content.id || "media"}
                                />;
                                case "pricing" : return <PricingSection
                                    containerProps={single.content.containerProps || {}}
                                    imageProps={single.content.imageProps || {}}
                                    iconProps = {single.content.iconProps || {}}
                                    linkProps={single.content.linkProps || {}}
                                    contentProps={single.content.contentProps || []}
                                    header={single.content.header || {}}
                                    backgroundColor={single.content.backgroundColor || "transparent"}
                                    buttonProps={single.content.buttonProps || {}}
                                    slider={single.content.slider || false}
                                    sectionStyle={single.content.sectionStyle || {}}
                                    id = { single.content.id || "pricing"}
                                    cardType = { single.cardType || "pricing"}
                                    template = {single.content.template || 1}
                                />
                                case "alternate": return <AlternateLayout
                                    reverse={single.content.reverse || false}
                                    header={single.content.header || {}}
                                    imageProps={single.content.imageProps || {}}
                                    videoProps={single.content.videoProps || {}}
                                    contentProps={single.content.contentProps || {}}
                                    linkProps={single.content.linkProps || {}}
                                    buttonProps = {single.content.buttonProps || {}}
                                    backgroundColor={single.content.backgroundColor || "transparent"}
                                    sectionStyle={single.content.sectionStyle || {}}
                                    id = { single.content.id || "alternate"}
                                    containerProps = { single.content.containerProps || {}}
                                />;
                                case "content" : return <ContentLayout
                                    title = {single.content.contentProps.title || ""}
                                    description = { single.content.contentProps.description || ""} 
                                    contentStyle = { { ...single.content.contentProps.contentStyle }}
                                    titleStyle = {single.content.contentProps.titleStyle || {}} 
                                    descriptionStyle = {single.content.contentProps.descriptionStyle || {}}
                                    subTitle={single.content.contentProps.subTitle || ""}
                                    subTitleStyle={single.content.contentProps.subTitleStyle || {}}
                                    extraClasses = {single.content.contentProps.extraClasses || "center"}
                                    tag = {single.content.contentProps.tag || {}}
                                    id = { single.content.id || "content"}
                                />;
                                case "widget" : return <NituWidget
                                    clientId = { appinfo["x-access-client-id"] || ""}
                                    formId = { appinfo["form"] || ""}
                                    width = { single.content.width || "100%"}
                                    height = { single.content.height || "400px"}
                                    style = { single.content.style || {}}
                                    id = { single.content.id || "widget"}
                                />
                                case "banner" : return <Banner
                                    container = {single.content || {}}
                                    template = {single.content.template || 2}
                                    id = { single.content.id || "banner"}
                                />
                                case "contact" : return <ContactLayout 
                                        id = { single.content.id || "contact"}
                                    />;
                                case "accordion" : return <Accordion 
                                        list = {single.content.list || {}} 
                                        sectionStyle = {single.content.sectionStyle || {} }
                                        header = {single.content.header || {}}
                                    />;
                                default : return <div></div>;
                            }
                        }
                        })
                    }
                </div>
                <Seo />

                <Footer />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { taskcreate, authentication } = state;
    return {
        taskcreate,
        authentication
    };
}
export default withRouter(connect(mapStateToProps)(Home));
