import React, { Component } from 'react';
import appinfo from "../../clientsJSON";
import { Button, Link, SingleImageLayout } from "..";
import "./index.css";

import { utils } from '../../utils';

export default class Menu extends Component {
    render() {
        let header = appinfo.header;
        const { backgroundColor } = this.props;

        return (
            <div style={{ backgroundColor: appinfo.background_color }}>
                <header className="header" 
                    style = {{backgroundColor : appinfo.header.backgroundColor || "#FFF"}}
                >
                    <Link link="/">
                        <SingleImageLayout
                            imageSource={appinfo.logo}
                            extraClasses={"logo"}
                            imageAlt={"logo"}
                            imageHeight={header.logo.imageProps.imageHeight || "64px"}
                            // imageWidth={
                            //     utils.getScreenDimensions() >= 768 ? 
                            //     (header.logo.imageProps.imageWidth  || "200px" )
                            //     : "150px"
                            // }
                        />
                    </Link>
                    <input className="menu-btn" type="checkbox" id="menu-btn" />
                    <label className="menu-icon" for="menu-btn">
                        <span className="navicon"></span>
                    </label>
                    <ul className="menu">
                        {header.menu.map(menu => {
                            return (
                                <li className="listmenu">
                                    { menu.type === "button" &&
                                        <Button
                                            buttonLink={menu.link || ""}
                                            buttonText={menu.value || ""}
                                            buttonStyle={{ ...menu.style ,  marginTop: 0 }}
                                            showIcon = { menu.showIcon || false }
                                            iconName = { menu.iconName || "" }
                                            iconReverse = { menu.iconReverse || false }
                                            newTab = { menu.newTab || false }
                                            extraClasses = { menu.extraClasses || "" }
                                            iconStyle = { menu.iconStyle || {} }
                                            razorpayButton = {menu.razorpayButton || false}
                                            paymentButtonId = {menu.paymentButtonId || ""}
                                            razorpayButtonStyle = {menu.razorpayButtonStyle || {}}
                                        />
                                    }
                                    { menu.type !== "button" &&
                                        <Link
                                            linkText={menu.value || ""}
                                            link={menu.link || ""}
                                            linkStyle={{ ...menu.style ,  marginTop: 5 }}
                                            showIcon = { menu.showIcon || false }
                                            iconName = { menu.iconName || "" }
                                            iconReverse = { menu.iconReverse || false }
                                            newTab = { menu.newTab || false }
                                            extraClasses = { 
                                                menu.extraClasses || 
                                                (   menu.value === "" && 
                                                    menu.iconName === 'phone' ?
                                                        "hide" : ""
                                                )
                                            }
                                            iconStyle = { menu.iconStyle || {} }
                                            borderBottom = { true }
                                        />
                                    }
                                </li>
                            );
                        })
                        }

                    </ul>
                </header>
            </div>
        )
    }
}